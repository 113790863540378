<template>
  <div class="q-pa-xl">
    <h4 class="text-h4">About Offboard HealthViz</h4>
    <div class="text-body2">
      HealthViz is used to visualize and succinctly display aggregated ADS health data for a
      particular vehicle gen 3 or greater.
      <br /><br />
      Offboard HealthViz has three pages in which to view fault events:
      <ul>
        <li>
          The Faults page displays ADS fault events in a condensed view, showing each Error and its
          respective Root Causes.
        </li>
        <li>
          The Trace page displays a more detailed view of the fault event traces, as a connected
          graph of the full trace.
        </li>
        <li>The Sensors page shows only sensor errors in a focused, more detailed view.</li>
      </ul>
    </div>

    Fault events can be viewed for one vehicle either live or at a particular point in time. These
    features are explored further below.

    <h4 class="text-h4">Viewing Fault events</h4>
    <div class="text-body2">
      A couple pieces need to be done before viewing a vehicle's fault events: selecting a vehicle
      and selecting a time.
    </div>

    <h5 class="text-h5">Selecting a Vehicle</h5>
    <div class="row">
      <div class="col-shrink">
        <img :src="`${baseUrl}images/docs/vehicle_select.png`" style="width: 300px" />
      </div>
      <div class="col q-mx-md text-body2">
        Start by selecting the vehicle you want to view fault events of by clicking the 'Search by
        Vehicle ID (Gen 3+ only)' input box.<br />
        From here, you can either start typing a vehicle ID or select one from the dropdown.
        <br /><br />
        The refresh button <q-icon name="refresh" /> can be used to reload the list of available
        vehicles. <br /><br />
        <hr />
        <br />
        Once a vehicle is selected, you can view more information about it at any time by hovering
        over the truck icon <q-icon name="local_shipping" /> next to the vehicle input box.
      </div>
      <div class="col-shrink">
        <img :src="`${baseUrl}images/docs/vehicle_info.png`" style="width: 300px" />
      </div>
    </div>

    <h5 class="text-h5">Selecting a Time</h5>
    <img :src="`${baseUrl}images/docs/time_buttons_paused.png`" style="width: 700px" />
    <div class="row q-mt-sm">
      <div class="col q-mr-md text-body2">
        Next, select a time frame in which to view the vehicle's fault events. If you want to see
        the latest available fault events and/or follow fault events of the vehicle as they come in,
        press the Go Live button (or the "w" key). Data will be refreshed about once every other
        second. You can press Pause (or "w") again to go back to Paused mode.
        <br /><br />
        For viewing past data, press the Jump to Time button to bring up the date and time picker.
        Here, you can select the exact day on the left, and the time on the right. The time
        selection works by first selecting the hour, then day, then second. Milliseconds can be
        manually modified in the timestamp input at the top.
        <br /><br />
        Alternatively, an ISO 8601 timestamp can be pasted into the timestamp input.
        <br /><br />
        <span class="text-bold">Note:</span> The times represented in the time picker are in UTC.
        <br /><br />
        Once a date and time are selected, press Jump.
        <br /><br />
        <hr />
        <br />
        Once you have selected a time from either using Go Live or Jump to Time, you can move
        backward ("q" key) and forward ("e" key) in time to see fault events before and after the
        time of the paused frame, respectively.
      </div>
      <div class="col-shrink">
        <img :src="`${baseUrl}images/docs/datetime_select.png`" style="width: 600px" />
      </div>
    </div>

    <h5 class="text-h5">Playback Selection Preservation</h5>
    <div class="text-body2">
      Playback button selections are retained across HealthViz pages. This means the following
      button clicks, when made in one page, will be present when navigating to another:

      <ul>
        <li>Whether playback is live or paused</li>
        <li>If playback is paused, the time in which the data is currently displayed</li>
      </ul>
      <span class="text-bold">Note:</span> user selections are
      <span class="text-bold">not</span> saved across browser tabs or full page loads/refreshes.
      <br /><br />
      For more information on each of the pages Offboard HealthViz offers, please continue below.
    </div>

    <h4 id="hm3" class="text-h4">Fault Page (Root Causes)</h4>
    <h5 class="text-h5">The Root Cause Card</h5>
    <div class="row">
      <div class="col-shrink">
        <img :src="`${baseUrl}images/docs/fault_card.png`" style="width: 250px" />
      </div>
      <div class="col q-ml-md text-body2">
        Root Cause cards are displayed as messages that arrive via the Health Monitoring API.
        Immediate Blames are filtered out, since they are duplicate or often impertinent
        information. Each Root Cause Card has two sections: the Error Title and the List of Root
        Causes.

        <h6 class="text-h6">Error Title</h6>
        The Error title is simply the ID of the Error.

        <h6 class="text-h6">Root Cause List</h6>
        The list of Root Causes can vary in length, but each card will have at least one. Each Root
        Cause can have up to four pieces of information, including its:

        <ul>
          <li><span class="text-weight-bold">ID</span>, in bold</li>
          <li>
            <span class="text-weight-bold">[Age]</span>, in bold and brackets, next to the Error ID
          </li>
          <li>Parameter, as labeled (Param)</li>
          <li>Source, as labeled</li>
        </ul>

        The Root Cause's <span class="text-weight-bold">[Age]</span> is colored either
        <span class="text-green">green</span>, <span class="text-yellow">yellow</span>, or
        <span class="text-orange">orange</span>, based off its age relative to other Root Causes.
        Each color represents a "bucket" the ages fall under, with each bucket comprising of roughly
        a third of all ages in the frame.

        <ul>
          <li><span class="text-green">Green</span> ages are the newest third of Root Causes.</li>
          <li>
            <span class="text-yellow">Yellow</span> ages are the older than green ages but younger
            than orange ones.
          </li>
          <li><span class="text-orange">Orange</span> ages are the oldest third of Root Causes.</li>
        </ul>
      </div>
    </div>

    <h6 class="text-h6">MRM Root Cause Cards</h6>
    <div class="row">
      <div class="col-shrink">
        <img :src="`${baseUrl}images/docs/mrm_card.png`" style="width: 250px" />
      </div>
      <div class="col q-ml-md text-body2">
        MRM (Minimal Risk Manuever) cards are hoisted above all others when present, on their own
        row. This is due to the importance and high priority of these errors.
        <br /><br />
        MRM cards are made more visually distinctive in the following ways:
        <ul>
          <li>The background color is slightly darker</li>
          <li>There is a thin red left border</li>
          <li>The error title is orange</li>
        </ul>

        <span class="text-bold">Note:</span> when an MRM card becomes cleared (see below), its
        styling is identical to any other Cleared card. However, it remains at the top of the page.
      </div>
    </div>

    <h6 class="text-h6">Cleared Root Cause Cards</h6>
    <div class="row">
      <div class="col-shrink">
        <img :src="`${baseUrl}images/docs/cleared_card.png`" style="width: 250px" />
      </div>
      <div class="col q-ml-md text-body2">
        When a message is "Cleared", it means the error is no longer virtually present. However, it
        will remain displayed for a brief period of time before disappearing.
        <br /><br />
        Similar to MRM cards, Cleared cards are visually denoted in the following ways:
        <ul>
          <li>The background color is slightly darker</li>
          <li>There is a thin green left border</li>
          <li>The error title is green</li>
          <li>A green checkmark is present at the top-right of the card</li>
        </ul>
      </div>
    </div>

    <h4 id="trace-page" class="text-h4">Trace Page</h4>
    <img :src="`${baseUrl}images/docs/trace_page.png`" style="width: 800px" />
    <p class="text-body2">
      The Trace page offers a full view of the entire Fault event trace, including the connection of
      every Immediate Blame. Just like the Faults page, this data can be viewed live or at a
      specific time.
    </p>

    <h5 class="text-h5">Other Features</h5>
    <img :src="`${baseUrl}images/docs/trace_page_feature_btns.png`" style="width: 425px" />
    <div class="text-body2">
      A couple additional buttons are offered on the Trace page:
      <ul>
        <li>
          The graph can be rotated by pressing "r" or the Rotate Graph button. This button toggles
          displaying the graph in either top-to-bottom (the default) or left-to-right layout.
        </li>
        <li>
          An image of the graph can also be copied as a PNG or saved as either a PNG or SVG. This is
          accessible from the Copy/Save Image dropdown.
        </li>
      </ul>
      <span class="text-weight-bold">Note:</span> additional steps are required to copy an image in
      Firefox. Please see below.
    </div>
    <h6 id="ff-image-copy" class="text-h6">Enabling Image Copying in Firefox</h6>
    <img
      :src="`${baseUrl}images/docs/firefox_clipboard_pref.png`"
      style="width: 800px; height: 155px"
    />
    <div class="text-body2">
      In order to enable copying images to the clipboard in Firefox, a small amount of configuration
      is required. Follow these steps:

      <ol>
        <li>In a new tab, go to about:config</li>
        <li>Enter "clipboardItem" in the search box</li>
        <li>
          Double-click on "dom.events.asyncClipboard.clipboardItem" so that it gets set to "true"
          and becomes emboldened
        </li>
        <li>Exit the about:config tab and refresh HealthViz</li>
      </ol>
    </div>

    <h4 id="sensors-page" class="text-h4">Sensors Page</h4>
    <img :src="`${baseUrl}images/docs/sensors_page.png`" style="width: 800px" />
    <p class="text-body2">
      The Sensors page dives deeper into Sensor-specific errors. This includes the sensor's name and
      ID, as well as the error's name and type. Additional information for each error can be seen by
      pressing the plus button <q-icon name="add_circle" />.
    </p>
  </div>
</template>

<script lang="ts" setup>
const baseUrl = import.meta.env.BASE_URL
</script>

<style lang="scss" scoped>
.hyperlink {
  color: $light-blue-5;
  text-decoration: none;
  &:hover {
    color: $light-blue-4;
    text-decoration: underline;
  }
}
.header-link {
  @extend .hyperlink;
  color: white;
  &:hover {
    color: white;
  }
}
.dark-color {
  color: rgba(255, 255, 255, 0.8);
}
.doc-note {
  border-style: solid;
  border-radius: 5px;
  border-color: $warning;
}
</style>
