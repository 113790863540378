import axios from 'axios'
import { ref } from 'vue'
import { Notify } from 'quasar'
import { FaultEvent } from 'src/models'
import { useConfigStore, useVehiclesStore } from 'src/stores'
import { LogAndNotifyAxiosError } from './util'
import {
  LogElapsedTimeFetchLatestFault,
  LogElapsedTimeFetchFaultEventByDateWindow,
  LogDataDogMessage,
  MetricType,
  LogKeys,
} from 'src/helpers/datadog'

export const healthMonitoringAPIStoreInternal = () => {
  const vehiclesStore = useVehiclesStore()
  let controller = new AbortController()
  const lastKnownSelectedVehicle = ref<string | undefined>(undefined)

  async function fetchLastFaultEvent(): Promise<FaultEvent | undefined> {
    const apiUrl = String(import.meta.env.VITE_HEALTH_MONITOR_API_URL)
    const url = `${apiUrl}/v1/fault-events/$latest`
    const vehicleId = vehiclesStore.selectedVehicleId
    const start = undefined
    const end = undefined
    const count = undefined
    const cursor = undefined

    const params = { vehicle_id: vehicleId, start, end, cursor, count }

    const startTime = new Date()

    try {
      const response = await axios.get<FaultEvent>(url, {
        signal: controller.signal,
        params,
      })
      LogElapsedTimeFetchLatestFault(startTime, new Date(), response)
      return response.data
    } catch (error) {
      if (axios.isCancel(error)) {
        console.debug(`Request to ${url} cancelled`)
      } else if (axios.isAxiosError(error)) {
        if (!error.response) {
          LogAndNotifyAxiosError(error, 'Error occurred calling: ' + url)
          return
        }

        if (error.response.status >= 500 && error.response.status < 600) {
          LogDataDogMessage(
            MetricType.RELIABILITY,
            'health-monitoring-api',
            `server error ${error.response.status}`,
            'error',
            {
              status: error.response.status,
              statusText:
                error.response.status >= 400
                  ? error.response.statusText || (error.response.data as Record<string, unknown>)
                  : error.response.statusText,
              url: error.response.config?.url ?? 'Unknown URL',
              log_key: LogKeys.HEALTH_MON_5xx_ERROR,
            }
          )
        }
        LogElapsedTimeFetchLatestFault(startTime, new Date(), error.response)
        LogAndNotifyAxiosError(error, 'Error occurred calling: ' + url)
      } else {
        console.error(error)
        Notify.create({
          type: 'negative',
          icon: 'warning',
          message: JSON.stringify(error),
        })
      }
    }
  }

  async function fetchFaultEventByDateWindow(
    start: number, // epoch milliseconds
    end: number, // epoch milliseconds
    sort: 'desc' | 'asc' // sorting in search: descending (newest first) or ascending (oldest first)
  ): Promise<FaultEvent[] | undefined> {
    const apiUrl = String(import.meta.env.VITE_HEALTH_MONITOR_API_URL)
    const url = `${apiUrl}/v1/fault-events`
    const store = useConfigStore()

    const vehicleId = vehiclesStore.selectedVehicleId
    const count = store.countFaultsPerRequestsHealthMonitoringApi
    const cursor = undefined
    const params = { vehicle_id: vehicleId, start, end, cursor, count, sort }

    const startTime = new Date()

    try {
      const response = await axios.get<FaultEvent[]>(url, {
        signal: controller.signal,
        params,
      })
      LogElapsedTimeFetchFaultEventByDateWindow(startTime, new Date(), response)
      return response.data
    } catch (error) {
      if (axios.isCancel(error)) {
        console.debug(`Request to ${url} cancelled`)
      } else if (axios.isAxiosError(error)) {
        if (error.response) {
          if (error.response.status >= 500 && error.response.status < 600) {
            LogDataDogMessage(
              MetricType.RELIABILITY,
              'health-monitoring-api',
              `server error ${error.response.status}`,
              'error',
              {
                status: error.response.status,
                statusText:
                  error.response.status >= 400
                    ? error.response.statusText || (error.response.data as Record<string, unknown>)
                    : error.response.statusText,
                url: error.response.config?.url ?? 'Unknown URL',
                log_key: LogKeys.HEALTH_MON_5xx_ERROR,
              }
            )
          }
          LogElapsedTimeFetchFaultEventByDateWindow(startTime, new Date(), error.response)
        }
        LogAndNotifyAxiosError(error, 'Error occurred calling: ' + url)
      } else {
        console.error(error)
        Notify.create({
          type: 'negative',
          icon: 'warning',
          message: JSON.stringify(error),
        })
      }
    }
  }

  vehiclesStore.$subscribe((_ /*mutation*/, state) => {
    if (lastKnownSelectedVehicle.value === state.selectedVehicleId) {
      return
    }

    if (lastKnownSelectedVehicle.value) {
      // cancel all in-flight axios requests
      controller.abort()
      // re-up controller on abort, or no further requests can be made
      controller = new AbortController()
    }

    lastKnownSelectedVehicle.value = state.selectedVehicleId
  })

  return {
    fetchLastFaultEvent,
    fetchFaultEventByDateWindow,
  }
}
