import { configStoreInternal } from './config'
import { frameBufferStoreInternal } from './frame_buffer'
import { healthMonitoringAPIStoreInternal } from './health_monitoring_api'
import { faultsStoreInternal } from './faults'
import { timeUpdateStoreInternal } from './time_update'
import { navigationStoreInternal } from './navigation'
import { vehiclesApiStoreInternal } from './vehicles_api'
import { vehiclesStoreInternal } from './vehicles'
import { sensorFaultStoreInternal } from './sensors'
import { usersStoreInternal } from './users'
import { defineStore } from 'pinia'

// Set up the stores
export const useConfigStore = defineStore('config', configStoreInternal)
export const useFrameBufferStore = defineStore('frame_buffer', frameBufferStoreInternal)
export const useHealthMonitoringAPIStore = defineStore(
  'health_monitoring_api',
  healthMonitoringAPIStoreInternal
)
export const useFaultsStore = defineStore('faults', faultsStoreInternal)
export const useTimeUpdateStore = defineStore('time_update', timeUpdateStoreInternal)
export const useNavigationStore = defineStore('navigation', navigationStoreInternal)
export const useVehiclesApiStore = defineStore('vehicles_api', vehiclesApiStoreInternal)
export const useVehiclesStore = defineStore('vehicles', vehiclesStoreInternal)
export const useSensorFaultStore = defineStore('sensorFaultStore', sensorFaultStoreInternal)
export const useUsersStore = defineStore('users', usersStoreInternal)
