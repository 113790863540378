// Datadog RUM user action tracking: elements
export enum DatadogRumElement {
  // Time Navigation elements
  TogglePlaybackBtn = 'Toggle Playback btn',
  RewindFrameBtn = 'Rewind frame btn',
  ForwardFrameBtn = 'Forward frame btn',
  JumpToTimeOpenBtn = 'Jump to Time Open btn',
  JumpToTimeCancelBtn = 'Jump to Time Cancel btn',
  JumpToTimeJumpBtn = 'Jump to Time Jump btn',
  CopyTimestampBtn = 'Copy Timestamp btn',
  // Vehicle Selection elements
  VehicleSelectionInput = 'Vehicle Selection input',
  RefreshVehicleListBtn = 'Refresh Vehicle List btn',
  VehicleInfoTooltip = 'Vehicle Information tooltip',
  // HM Trace elements
  SavePNGBtn = 'Save PNG btn',
  CopyPNGBtn = 'Copy PNG btn',
  SaveSVGBtn = 'Save SVG btn',
  RotateGraphBtn = 'Rotate Graph btn',
  // Sensors elements
  SensorsTableRowHeader = 'Sensors Table Row Header',
  SensorsTablePageBtn = 'Sensors Table Page btn',
  SensorsTableRowsPerPageDropdown = 'Sensors Table Rows Per Page dropdown',
  SensorsExpandRowBtn = 'Sensors Expand Row btn',
  // Navbar button elements
  NavbarLogoBtn = 'Navbar Logo btn',
  NavbarFaultsBtn = 'Navbar Faults btn',
  NavbarTraceBtn = 'Navbar Trace btn',
  NavbarSensorsBtn = 'Navbar Sensors btn',
  NavbarDocsBtn = 'Navbar Docs btn',
}

// Datadog RUM user action tracking: methods
export enum DatadogRumMethod {
  ButtonClick = 'Button click',
  ButtonHover = 'Button hover',
  InputClick = 'Input click',
  // A mouse click that wasn't targeted towards a specific element. Ex: clicking a dialog background
  NontargetedMouseClick = 'Nontargeted mouse click',
  Keybind = 'Keybind',
  Unknown = 'Unknown',
}

// Datadog RUM user action tracking: user inputs
export enum DatadogRumInput {
  MouseTouchpad = 'Mouse / Touchpad',
  TogglePlaybackKeybind = 'W',
  RewindKeybind = 'Q',
  ForwardKeybind = 'E',
  EscKey = 'Esc',
  Unknown = 'Unknown',
}
