<template>
  <div v-if="showButtons" v-bind="$attrs">
    <q-btn-group>
      <q-btn
        id="hm-trace-back-btn"
        icon="fast_rewind"
        label="Back (Q)"
        @click="componentStore.rewindFrame(true, true)"
      />
      <q-btn
        id="hm-trace-fwd-btn"
        icon="fast_forward"
        label="Forward (E)"
        @click="componentStore.forwardFrame(true, true)"
      />
    </q-btn-group>
    <q-btn icon="rotate_right" label="Rotate Graph (R)" @click="rotateGraph" />
    <q-btn-group>
      <q-btn icon="content_copy" label="Copy PNG" @click="copyPNG" />
      <q-btn icon="download_for_offline" label="Save PNG" @click="savePNG" />
      <q-btn icon="download_for_offline" label="Save SVG" @click="saveSVG" />
    </q-btn-group>
  </div>

  <div id="hm-trace-graph" class="scroll" />
</template>

<script>
/* eslint-disable
  @typescript-eslint/no-unsafe-assignment,
  @typescript-eslint/no-unsafe-call,
  @typescript-eslint/no-unsafe-member-access,
  @typescript-eslint/no-unsafe-return,
  @typescript-eslint/no-unsafe-argument,
  @typescript-eslint/no-floating-promises
*/
import { Canvg } from 'canvg'
import { mapStores } from 'pinia'
import { datadogRum } from '@datadog/browser-rum'
import Viz from './viz/viz.js'
import { Module, render } from './viz/lite.render.js'
import { generateGraph, Dot } from './DotCreator.js'
import { DatadogRumElement, DatadogRumMethod, DatadogRumInput } from 'src/models'
import { useConfigStore } from 'src/stores'
const { ClipboardItem } = window

export default {
  props: {
    message: {
      type: Array,
      required: true,
    },
    showButtons: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    const configStore = useConfigStore()
    return {
      rankDir: configStore.traceRenderDirection,
    }
  },
  watch: {
    message() {
      this.drawSVG()
    },
  },
  mounted() {
    Dot.Graph.RANK_DIRECTION = this.rankDir
    this.drawSVG()
  },
  methods: {
    // Rendering functions
    drawGraph() {
      // Re-reverse the message in LR orientation due to it getting reversed there for some reason
      return generateGraph(
        this.rankDir === 'LR' ? this.message.toReversed() : this.message
      ).snapshot()
    },
    drawSVG() {
      new Viz({ Module, render }).renderString(this.drawGraph()).then((svg) => {
        document.getElementById('hm-trace-graph').innerHTML = svg
      })
    },
    // Button functions
    rotateGraph() {
      // Do datadog logging
      datadogRum.addAction('Rotate HM Trace', {
        element: DatadogRumElement.RotateGraphBtn,
        method: DatadogRumMethod.ButtonClick,
        input: DatadogRumInput.MouseTouchpad,
        action: 'Rotate HM Trace',
      })

      this.rankDir = this.rankDir === 'TB' ? 'LR' : 'TB'
      Dot.Graph.RANK_DIRECTION = this.rankDir
      this.drawSVG()
    },
    getSvgOnCanvas() {
      // Draw the svg on a fake canvas
      try {
        const svgEl = document
          .getElementById('hm-trace-graph')
          .getElementsByTagName('svg')[0].outerHTML
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')
        ctx.canvas.width = document.getElementById('hm-trace-graph').offsetWidth
        ctx.canvas.height = document.getElementById('hm-trace-graph').offsetHeight
        const v = Canvg.fromString(ctx, svgEl)
        v.start()
        return canvas
      } catch (error) {
        console.error(error)
        this.$q.notify({
          message: 'Unable to copy/save PNG',
          caption: 'The PNG could not be created',
          type: 'negative',
          icon: 'warning',
          timeout: 3000,
        })
      }
    },
    copyPNG() {
      // Do datadog logging
      datadogRum.addAction('Copy HM Trace PNG', {
        element: DatadogRumElement.CopyPNGBtn,
        method: DatadogRumMethod.ButtonClick,
        input: DatadogRumInput.MouseTouchpad,
        action: 'Copy PNG',
      })

      const canvas = this.getSvgOnCanvas()
      if (!canvas) return

      // Use the canvas to encode image data
      canvas.toBlob((blob) => {
        try {
          navigator.clipboard.write([new ClipboardItem({ 'image/png': blob })]).then(() => {
            this.$q.notify({
              message: 'Image Copied!',
              type: 'positive',
              icon: 'check_circle',
              timeout: 1500,
            })
          })
        } catch (error) {
          console.error(error)
          this.$q.notify({
            message: 'Error copying PNG to clipboard',
            caption:
              'Please allow images to be copied to the clipboard. See the Trace Page docs if you are using Firefox.',
            type: 'warning',
            icon: 'priority_high_black',
            timeout: 0,
            multiLine: true,
            actions: [
              {
                label: 'Go To Docs',
                color: 'black',
                handler: () => {
                  window.open('/docs', '_blank')
                },
              },
              {
                label: 'Dismiss',
                color: 'black',
              },
            ],
          })
          return nil
        }
      })
    },
    savePNG() {
      // Do datadog logging
      datadogRum.addAction('Save HM Trace PNG', {
        element: DatadogRumElement.SavePNGBtn,
        method: DatadogRumMethod.ButtonClick,
        input: DatadogRumInput.MouseTouchpad,
        action: 'Save PNG',
      })

      const canvas = this.getSvgOnCanvas()
      if (!canvas) return

      // Use the canvas to encode image data
      const img = canvas.toDataURL('image/png')
      // Download the PNG
      const dl = document.createElement('a')
      dl.href = img
      dl.download = 'hm_trace.png'
      dl.click()
    },
    saveSVG() {
      // Do datadog logging
      datadogRum.addAction('Save HM Trace SVG', {
        element: DatadogRumElement.SaveSVGBtn,
        method: DatadogRumMethod.ButtonClick,
        input: DatadogRumInput.MouseTouchpad,
        action: 'Save SVG',
      })

      new Viz({ Module, render }).renderString(this.drawGraph()).then((svg) => {
        const element = document.createElement('a')
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(svg))
        element.setAttribute('download', 'hm_trace.svg')
        element.style.display = 'none'
        document.body.appendChild(element)
        element.click()
        document.body.removeChild(element)
      })
    },
  },
}
</script>
