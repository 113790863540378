export default class Gradient {
  constructor(colorA, colorB) {
    const self = this
    self.colA = colorA
    self.colB = colorB
  }

  shade() {
    const self = this
    return `${self.colA}:${self.colB}`
  }
}
