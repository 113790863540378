import { ref, computed, ComputedRef } from 'vue'

import { FaultEvent, SystemFaultErrorCodes } from 'src/models/fault_event'
import { RootCauseError, healthItemsToRootCauseErrors } from 'src/models/root_cause'
import { useConfigStore, useHealthMonitoringAPIStore, useVehiclesStore } from 'src/stores'
import { LogFaultBufferUsage } from 'src/helpers/datadog'
import { timeDifference } from 'src/stores/util'

export const faultsStoreInternal = () => {
  // ----------------- //
  // Other stores used //
  // ----------------- //
  const configStore = useConfigStore()
  const healthMonitoringApiStore = useHealthMonitoringAPIStore()

  // ----- //
  // State //
  // ----- //
  const faultEventsBuffer = ref(new Map<number, FaultEvent>()) // Key: fault event's timestamp
  const activeFaultEventTimestamp = ref<number | null>(null)
  const faultEventsBufferOldestTimestamp = ref<number | null>(null)
  const faultEventsBufferLatestTimestamp = ref<number | null>(null)
  const lastUpdatedWithLatest = ref(false)
  const lastKnownSelectedVehicle = ref<string | undefined>(undefined)

  // -------- //
  // Computed //
  // -------- //
  const rootCauseErrors: ComputedRef<RootCauseError[] | never[]> = computed(() => {
    if (!activeFaultEventTimestamp.value) {
      return []
    }
    return healthItemsToRootCauseErrors(
      faultEventsBuffer.value.get(activeFaultEventTimestamp.value)?.fault_message.health_items,
      activeFaultEventTimestamp.value
    )
  })
  const mrmRequestActive: ComputedRef<boolean> = computed(() =>
    rootCauseErrors.value.some((f) => f.error_code === SystemFaultErrorCodes.MRMRequest)
  )
  const systemUnhealthy: ComputedRef<boolean> = computed(() =>
    rootCauseErrors.value.some((f) => f.error_code === SystemFaultErrorCodes.SystemUnhealthy)
  )
  const prioritizedRootCauseErrors: ComputedRef<RootCauseError[] | never[]> = computed(() =>
    rootCauseErrors.value.toSorted((err1, err2) => {
      // Default to a high-numbered priority (which is to say, bottom of the list) if not found
      const err1Priority = configStore.componentFaultPriority[err1.component_id] || 1000
      const err2Priority = configStore.componentFaultPriority[err2.component_id] || 1000
      return err1Priority - err2Priority
    })
  )

  // ------- //
  // Actions //
  // ------- //
  function reset(): void {
    faultEventsBuffer.value.clear()
    activeFaultEventTimestamp.value = null
    faultEventsBufferOldestTimestamp.value = null
    faultEventsBufferLatestTimestamp.value = null
  }

  /**
   * Updates the active fault to the latest one available.
   * Returns true if a fault was found, false otherwise.
   */
  async function updateActiveFaultToLatest(): Promise<boolean> {
    // Get last fault event from API
    const lastFaultEvent = await healthMonitoringApiStore.fetchLastFaultEvent()

    // Don't update faults if the vehicle has changed (when previously set). Axios cancellations
    // should cover most cases, but it's possible a vehicle changes after the request finishes
    if (
      !lastFaultEvent ||
      (lastKnownSelectedVehicle.value &&
        lastFaultEvent.vehicle_id !== lastKnownSelectedVehicle.value)
    ) {
      return false
    }

    // Reset buffer if starting to update to latest, to avoid gaps in buffer
    if (!lastUpdatedWithLatest.value) {
      reset()
      faultEventsBufferOldestTimestamp.value = lastFaultEvent?.timestamp || null
      lastUpdatedWithLatest.value = true
    }

    // Remove first element if max buffer size would be exceeded
    if (faultEventsBuffer.value.size === configStore.maxFaultEventBufferSize) {
      faultEventsBuffer.value.delete(faultEventsBufferOldestTimestamp.value || 0)
      faultEventsBufferOldestTimestamp.value = faultEventsBuffer.value.keys().next().value || null
    }

    // Update state
    faultEventsBuffer.value.set(lastFaultEvent.timestamp, lastFaultEvent)
    faultEventsBufferLatestTimestamp.value = lastFaultEvent.timestamp
    activeFaultEventTimestamp.value = lastFaultEvent.timestamp

    return true
  }

  /**
   * Fetches events from health-monitoring-api, then stores them in this store's state.
   * Returns true if at least one fault event was found, false otherwise.
   *
   * @param timestamp - Timestamp where to target the search for fault events
   */
  async function fetchAndStoreFaultEventsFromApi(timestamp: number): Promise<boolean> {
    // Fetch events BEFORE and AFTER the target timestamp from API
    const [faultEventsBefore, faultEventsAfter] = await Promise.all([
      healthMonitoringApiStore.fetchFaultEventByDateWindow(0, timestamp, 'desc'),
      healthMonitoringApiStore.fetchFaultEventByDateWindow(timestamp + 1, 999999999999999, 'asc'),
    ])

    // Return if no faults were found
    const noFaultsBefore = faultEventsBefore === undefined || faultEventsBefore.length === 0
    const noFaultsAfter = faultEventsAfter === undefined || faultEventsAfter.length === 0
    if (noFaultsBefore && noFaultsAfter) {
      return false
    }

    // Empty buffer
    reset()

    // Set values in buffer, placing oldest first
    if (faultEventsBefore) {
      // Place "before" fault events in reverse order, since newest are first in this array
      faultEventsBefore.reverse().forEach((faultEvent) => {
        faultEventsBuffer.value.set(faultEvent.timestamp, faultEvent)
      })
    }
    if (faultEventsAfter) {
      faultEventsAfter.forEach((faultEvent) => {
        faultEventsBuffer.value.set(faultEvent.timestamp, faultEvent)
      })
    }

    // Set values for oldest and newest timestamps
    const oldestFromBefore = faultEventsBefore?.at(0)?.timestamp
    const oldestFromAfter = faultEventsAfter?.at(0)?.timestamp
    faultEventsBufferOldestTimestamp.value = oldestFromBefore || oldestFromAfter || 0

    const newestFromAfter = faultEventsAfter?.at(faultEventsAfter.length - 1)?.timestamp
    const newestFromBefore = faultEventsBefore?.at(faultEventsBefore.length - 1)?.timestamp
    faultEventsBufferLatestTimestamp.value = newestFromAfter || newestFromBefore || 0

    return true
  }

  /**
   * Updates the active fault to the closest one around a given timestamp.
   * Returns true if at least one fault event was found, false otherwise.
   *
   * @param timestamp - Timestamp where to target the search for fault events
   *
   * @returns [boolean, string, number] Array with:
   *  - success flag
   *  - error/info string
   *  - activated timestamp
   */
  async function updateActiveFaultTimestamp(timestamp: number): Promise<[boolean, string, number]> {
    // Invalidate state tracking flag
    lastUpdatedWithLatest.value = false

    // Fetch events if no data, or the timestamp is outside of the buffer's current time window
    let bufferUsed = true
    const isEmpty =
      !faultEventsBufferOldestTimestamp.value ||
      !faultEventsBufferLatestTimestamp.value ||
      !activeFaultEventTimestamp.value
    const isOlder = timestamp < (faultEventsBufferOldestTimestamp.value || 0)
    const isNewer = timestamp > (faultEventsBufferLatestTimestamp.value || 0)
    if (isEmpty || isOlder || isNewer) {
      bufferUsed = false
      const foundFaultEvents = await fetchAndStoreFaultEventsFromApi(timestamp)
      if (!foundFaultEvents) {
        return [false, 'No fault events found for this vehicle', 0]
      }
    }
    LogFaultBufferUsage(bufferUsed)

    // Get the closest timestamp and set it as the active fault event
    let smallestDiff: number | undefined = undefined
    let closestTimestamp = 0
    for (const eventTimestamp of faultEventsBuffer.value.keys()) {
      const diff = Math.abs(eventTimestamp - timestamp)
      if (smallestDiff === undefined || diff <= smallestDiff) {
        smallestDiff = diff
        closestTimestamp = eventTimestamp
        continue
      }
      break
    }

    activeFaultEventTimestamp.value = closestTimestamp

    return [true, 'Selected closest timestamp', closestTimestamp]
  }

  /**
   * Updates the active fault to the one immediately previous to the current active fault.
   * If that event lies outside of the buffer, a request for fetching new fault events is triggered.
   *
   * @returns [boolean, string, string?] Tuple with success flag, error/info string, and optional details
   */
  async function updateActiveFaultPrevious(): Promise<[boolean, string, string?]> {
    // Invalidate state tracking flag
    lastUpdatedWithLatest.value = false

    // Keep track of active timestamp in case the buffer is reset while fetching from API
    const activeTimestamp = activeFaultEventTimestamp.value

    let bufferUsed = true
    // Fetch events if the active fault is the oldest fault event of the buffer
    if (
      faultEventsBufferOldestTimestamp.value === null ||
      activeTimestamp === null ||
      faultEventsBufferOldestTimestamp.value === activeTimestamp
    ) {
      bufferUsed = false
      const foundFaultEvents = await fetchAndStoreFaultEventsFromApi(activeTimestamp || 0)
      if (!foundFaultEvents) {
        return [false, 'No fault events found for this vehicle']
      }
    }
    LogFaultBufferUsage(bufferUsed)

    // Find the timestamp right before the current active fault event
    let previousTimestamp = faultEventsBufferOldestTimestamp.value || 0
    for (const timestamp of faultEventsBuffer.value.keys()) {
      if (timestamp === activeTimestamp) {
        break
      }
      previousTimestamp = timestamp
    }

    let message = ''
    let caption = ''
    if (!bufferUsed && previousTimestamp === faultEventsBufferOldestTimestamp.value) {
      message = 'Active timestamp unchanged (oldest available)'
    } else if (
      activeFaultEventTimestamp.value &&
      activeFaultEventTimestamp.value - previousTimestamp > configStore.timeGapNotifyMilliseconds
    ) {
      // Display a detailed Notify if there's a (relatively) large time difference between frames
      const timeDiff = timeDifference(activeFaultEventTimestamp.value, previousTimestamp, false)
      message = 'Large time difference between frames'
      caption = `A time difference of ${timeDiff} was detected between frames`
    }

    // Set the active fault event
    activeFaultEventTimestamp.value = previousTimestamp

    return [true, message, caption]
  }

  /**
   * Updates the active fault to the one immediately following the current active fault.
   * If that event lies outside of the buffer, a request for fetching new fault events is triggered.
   *
   * @returns [boolean, string] Tuple with success flag and error/info string
   */
  async function updateActiveFaultNext(): Promise<[boolean, string, string?]> {
    // Invalidate state tracking flag
    lastUpdatedWithLatest.value = false

    // Keep track of active timestamp in case the buffer is reset while fetching from API
    const activeTimestamp = activeFaultEventTimestamp.value

    let bufferUsed = true
    // Fetch events if the active fault is the newest fault event of the buffer
    if (
      faultEventsBufferLatestTimestamp.value === null ||
      activeTimestamp === null ||
      faultEventsBufferLatestTimestamp.value === activeTimestamp
    ) {
      bufferUsed = false
      const foundFaultEvents = await fetchAndStoreFaultEventsFromApi(activeTimestamp || 0)
      if (!foundFaultEvents) {
        return [false, 'No fault events found for this vehicle']
      }
    }
    LogFaultBufferUsage(bufferUsed)

    // Find the timestamp right after the current active fault event
    let foundMatch = false
    let nextTimestamp = faultEventsBufferLatestTimestamp.value || 0
    for (const timestamp of faultEventsBuffer.value.keys()) {
      if (foundMatch) {
        nextTimestamp = timestamp
        break
      }
      if (timestamp === activeTimestamp) {
        foundMatch = true
      }
    }

    let message = ''
    let caption = ''
    if (!bufferUsed && nextTimestamp === faultEventsBufferLatestTimestamp.value) {
      message = 'Active timestamp unchanged (newest available)'
    } else if (
      activeFaultEventTimestamp.value &&
      nextTimestamp - activeFaultEventTimestamp.value > configStore.timeGapNotifyMilliseconds
    ) {
      // Display a detailed Notify if there's a (relatively) large time difference between frames
      const timeDiff = timeDifference(nextTimestamp, activeFaultEventTimestamp.value, false)
      message = 'Large time difference between frames'
      caption = `A time difference of ${timeDiff} was detected between frames`
    }

    // Set the active fault event
    activeFaultEventTimestamp.value = nextTimestamp

    return [true, message, caption]
  }

  const vehiclesStore = useVehiclesStore()
  vehiclesStore.$subscribe((_ /*mutation*/, state) => {
    if (lastKnownSelectedVehicle.value === state.selectedVehicleId) {
      return
    }
    lastKnownSelectedVehicle.value = state.selectedVehicleId

    reset()
    lastUpdatedWithLatest.value = false
  })

  return {
    faultEventsBuffer,
    activeFaultEventTimestamp,
    faultEventsBufferOldestTimestamp,
    faultEventsBufferLatestTimestamp,
    lastUpdatedWithLatest,
    rootCauseErrors,
    mrmRequestActive,
    systemUnhealthy,
    prioritizedRootCauseErrors,
    reset,
    updateActiveFaultToLatest,
    updateActiveFaultTimestamp,
    updateActiveFaultPrevious,
    updateActiveFaultNext,
  }
}
