<template>
  <q-btn-group class="q-pa-md bg-grey-9_5" unelevated spread>
    <q-btn
      id="trace-page-rotate-btn"
      icon="rotate_right"
      label="Rotate Graph (R)"
      color="primary"
      @click="hmTrace?.rotateGraph"
    >
      <q-tooltip>Keybind: R</q-tooltip>
    </q-btn>
    <q-btn-dropdown
      id="trace-page-save-btn"
      class="col-6 q-pl-xs"
      icon="download_for_offline"
      dropdown-icon="arrow_drop_down"
      label="Copy/Save Image"
      color="primary"
      unelevated
    >
      <q-list>
        <q-item clickable @click="hmTrace?.copyPNG">
          <q-item-section avatar>
            <q-icon name="content_copy" />
          </q-item-section>
          <q-item-section>Copy PNG</q-item-section>
        </q-item>
        <q-item clickable @click="hmTrace?.savePNG">
          <q-item-section avatar>
            <q-icon name="download_for_offline" />
          </q-item-section>
          <q-item-section>Save PNG</q-item-section>
        </q-item>
        <q-item clickable @click="hmTrace?.saveSVG">
          <q-item-section avatar>
            <q-icon name="download_for_offline" />
          </q-item-section>
          <q-item-section>Save SVG</q-item-section>
        </q-item>
      </q-list>
    </q-btn-dropdown>
  </q-btn-group>

  <div v-if="faultsStore.prioritizedRootCauseErrors.length > 0" class="q-pa-xl">
    <HMTrace
      ref="hmTrace"
      :message="faultsStore.prioritizedRootCauseErrors"
      :show-buttons="false"
    />
  </div>

  <NoFaultsPlaceholder v-else :displayed-entity="'faults'" />
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import { useKeypress } from 'vue3-keypress'
import { useFaultsStore } from 'src/stores'
// @ts-expect-error: No declaration file for module
import HMTrace from 'src/components/hm_trace/HMTrace.vue'
import NoFaultsPlaceholder from 'src/components/NoFaultsPlaceholder.vue'

const faultsStore = useFaultsStore()

const hmTrace = ref<InstanceType<typeof HMTrace>>()

/* Keybinds */
useKeypress({
  keyEvent: 'keydown',
  keyBinds: [
    {
      keyCode: 82, // key "r"
      // Disable eslint TS rules on rotateGraph() due to build TS not picking up component type
      /* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,
      @typescript-eslint/no-unsafe-call */
      success: () => void hmTrace.value?.rotateGraph(),
      preventDefault: false,
    },
  ],
})
</script>
