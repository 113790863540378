<!-- eslint-disable vue/attribute-hyphenation -->
<template>
  <div v-if="displayMRMsErrors.length + displayErrors.length > 0">
    <div class="root-cause-cards-wrapper q-ma-md">
      <RootCauseCard
        v-for="displayError in displayMRMsErrors"
        :id="`mrm-cause-card-${displayError.ref}`"
        :key="displayError.ref"
        :root-cause-error="displayError"
        :mapped-errors="mappedErrors"
        :isMRM="true"
      />
    </div>
    <div class="root-cause-cards-wrapper q-ma-md">
      <RootCauseCard
        v-for="displayError in displayErrors"
        :id="`root-cause-card-${displayError.ref}`"
        :key="displayError.ref"
        :root-cause-error="displayError"
        :mapped-errors="mappedErrors"
        :isMRM="false"
      />
    </div>
  </div>
  <NoFaultsPlaceholder v-else :displayed-entity="'faults'" />
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { useFaultsStore } from 'src/stores'
import { RootCauseError, ErrorMap, SystemFaultErrorCodesValues } from 'src/models'
import { insertWordBreaks } from 'src/stores/util'
import RootCauseCard from 'src/components/RootCauseCard.vue'
import NoFaultsPlaceholder from 'src/components/NoFaultsPlaceholder.vue'

const faultsStore = useFaultsStore()

// The list of non-MRM errors to ultimately display
const displayErrors = computed<RootCauseError[] | never[]>(() =>
  faultsStore.rootCauseErrors
    .filter(
      (rootCauseError) =>
        !rootCauseError.is_immediate_cause &&
        !SystemFaultErrorCodesValues.includes(rootCauseError.error_code)
    )
    .map((rootCauseError) => {
      rootCauseError.error.id = insertWordBreaks(rootCauseError.error.id)
      return rootCauseError
    })
)

// The list of MRM errors to ultimately display
const displayMRMsErrors = computed<RootCauseError[] | never[]>(() =>
  faultsStore.rootCauseErrors
    .filter((rootCauseError) => SystemFaultErrorCodesValues.includes(rootCauseError.error_code))
    .map((rootCauseError) => {
      rootCauseError.error.id = insertWordBreaks(rootCauseError.error.id)
      return rootCauseError
    })
)

// Map the root cause errors in { ref: rootCause } format so they can easily
// be referenced in the Root Cause section of each card
const mappedErrors = computed(() => {
  let errorMap: ErrorMap = {}
  faultsStore.rootCauseErrors.forEach((err) => {
    errorMap[err.ref] = err
  })
  return errorMap
})
</script>

<style lang="scss" scoped>
.root-cause-cards-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start; // Make items not stretch full height
  gap: map-get($space-md, x);
}
</style>
