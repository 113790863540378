/*
Serious Vizness
Robbie Grier
*/

import SVCluster from './SVCluster'
import SVEdge from './SVEdge'
import SVNode from './SVNode'

export default class SeriousVizness {
  constructor() {
    const self = this

    // Components
    self.nodes = []
    self.edges = []
    self.clusters = []

    // Graph Defaults
    self.backgroundColor = 'black'
    self.rankSeparation = '0.0'
    self.rankDirection = 'TB'
    self.useSplines = 'true'
    self.size = '100'

    // Node Defaults
    self.nodeColor = 'darkgreen'
    self.nodeShape = 'rect'
    self.nodeFontColor = 'white'
    self.nodeFontStyle = 'inherit'
    self.nodeStyle = 'filled'

    // Edge Defaults
    self.edgeColor = 'darkgreen'
    self.edgeFontColor = 'white'
    self.edegeArrowHead = 'odot'
    self.edgeFontStyle = 'inherit'
    self.edgeWeight = '100000'
    self.edgeLabel = ''

    // Cluster Defaults
    self.clusterColor = 'gray21'
    self.clusterFontColor = 'white'
    self.clusterFontStyle = 'inherit'
    self.clusterLabel = 'cluster'
    self.clusterStyle = ''
  }

  node(name) {
    const self = this
    const node = new SVNode(score(name))
      .setFontColor(self.nodeFontColor)
      .setFontStyle(self.nodeFontStyle)
      .setStyle(self.nodeStyle)
      .setColor(self.nodeColor)
      .setShape(self.nodeShape)
      .setLabel(name)
    self.nodes.push(node)
    return node
  }

  edge(from, to) {
    const self = this
    const edge = new SVEdge(from, to)
      .setFontColor(self.edgeFontColor)
      .setFontStyle(self.edgeFontStyle)
      .setColor(self.edgeColor)
      .setWeight(self.edgeWeight)
      .setArrowHead(self.edegeArrowHead)
      .setLabel(self.edgeLabel)
    self.edges.push(edge)
    return edge
  }

  cluster(name) {
    const self = this
    const cluster = new SVCluster(score(name), name)
      .setFontColor(self.clusterFontColor)
      .setFontStyle(self.clusterFontStyle)
      .setLabel(self.clusterLabel)
      .setColor(self.clusterColor)
      .setStyle(self.clusterStyle)
    self.clusters.push(cluster)
    return cluster
  }

  attach(node, cluster) {
    const self = this
    if (self.nodes.includes(node)) {
      self.nodes.splice(self.nodes.indexOf(node), 1)
    }
    cluster.nodes.push(node)
  }

  setBackground(color) {
    const self = this
    self.backgroundColor = color
    return self
  }

  setRankSeparation(sep) {
    const self = this
    self.rankSep = sep
    return self
  }

  setRankDirection(dir) {
    const self = this
    self.rankDir = dir
    return self
  }

  setUseSplines(splines) {
    const self = this
    self.useSplines = splines ? 'true' : 'false'
    return self
  }

  setSize(size) {
    const self = this
    self.size = size
    return self
  }

  snapshot() {
    const self = this
    const generateHeader = function () {
      return `digraph {
        bgcolor="${self.backgroundColor}"
        ranksep=${self.rankSep}
        rankdir=${self.rankDir}
        size=${self.size}
        splines=${self.useSplines}`
    }

    // Writes the node lines
    const generateNodes = function () {
      let output = ``
      self.nodes.forEach((node) => {
        output += `${node.name}[label="${node.label}",shape=${node.shape},color="${node.color}",fontcolor="${node.fontColor}",style="${node.style}",layer=\"node\",id=${node.name},fontname="${node.fontStyle}"]\n`
      })
      return output
    }

    // Writes the cluster lines
    const generateClusters = function () {
      let output = ``
      self.clusters.forEach((cluster) => {
        output += `subgraph cluster_${cluster.name}{
          label="${cluster.label}"
          color="${cluster.color}"
          fontcolor="${cluster.fontColor}"
          style="${cluster.style}"
          layer=\"node\"
          id=${cluster.name}
          fontname=${cluster.fontStyle}\n`
        cluster.nodes.forEach((node) => {
          output += `${node.name}[label="${node.label}",shape=${node.shape},color="${node.color}",fontcolor="${node.fontColor}",style="${node.style}",layer=\"node\",id=${node.name},fontname="${node.fontStyle}"]\n`
        })
        output += '}\n'
      })
      return output
    }

    // Writes the edge lines
    const generateEdges = function () {
      let output = ``
      self.edges.forEach((edge) => {
        output += `${edge.from.name}->${edge.to.name}[label="${edge.label}",arrowhead="${edge.arrowHead}",style="${edge.style}",color="${edge.color}",fontcolor="${edge.fontColor}",fontname="${edge.fontStyle}",weight=${edge.weight}]\n`
      })
      return output
    }

    const generateFooter = function () {
      return `\n}`
    }

    return `${generateHeader()}
    ${generateNodes()}
    ${generateClusters()}
    ${generateEdges()}
    ${generateFooter()}`
  }
}

function score(str) {
  return `sv${str.replace(/\W/g, '_')}`
}
