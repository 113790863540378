<template>
  <div class="fixed-center text-h6 text-weight-regular">
    <span v-if="timeUpdateStore.isNoModeActive">Please select a vehicle</span>
    <span v-else-if="!faultsStore.activeFaultEventTimestamp">
      Please select a time with the <q-icon name="event" /> Jump or
      <q-icon name="play_arrow" /> Live buttons
    </span>
    <span v-else>
      🙌 &nbsp; No {{ props.displayedEntity }} reported at this specific time &nbsp; 🙌
    </span>
  </div>
</template>

<script setup lang="ts">
import { useFaultsStore, useTimeUpdateStore } from 'src/stores'

const faultsStore = useFaultsStore()
const timeUpdateStore = useTimeUpdateStore()

const props = defineProps<{
  displayedEntity: string
}>()
</script>
