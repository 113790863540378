/*
Dot Creator
Robbie Grier
*/

import SeriousVizness from './SeriousVizness'
import { Color, Gradient, RangedVibrance } from './shader'

const Dot = {
  Checks: {
    IS_AUTONOMY_STATE: (err) => {
      return err.error.topic == '#autonomy_state'
    },
    IS_SPURIOUS: (err) => {
      return !err.verified
    },
    IS_CLEARED: (err) => {
      return err.cleared
    },
    IS_ROOT: (err) => {
      return err.immediate_causes?.length === 0
    },
  },
  Graph: {
    BACKGROUND: new Color('#1a1a1a'),
    COLOR: new Color('#ffffff'),
    RANK_SEPARATION: '0.8',
    RANK_DIRECTION: 'TB',
    USE_SPLINES: false,
    SIZE: 100,
  },
  Node: {
    AGE: true,
    SOURCE: true,
    AGE_TAGLINE: (err) => {
      return `[${err.error.source}]`
    },
    SOURCE_TAGLINE: (err) => {
      // Use unicode double space char. With two regular spaces, the second space
      // gets rendered as an &nbsp, which can't be parsed by the PNG processor
      return `\u2000[${(err.age / 1000).toFixed(1)}]`
    },
  },
  NodeType: {
    Default: {
      COLOR: new RangedVibrance('#FF0000', 0xff, 0x66, [100, 200, 300, 400, 500]),
      SHAPE: 'rect',
      FILL_STYLE: 'filled',
      FONT_COLOR: new Color('#ffffff'),
    },
    Root: {
      COLOR: new RangedVibrance('#FF0000', 0xff, 0x66, [100, 200, 300, 400, 500]),
      SHAPE: 'rect',
      FILL_STYLE: 'filled',
      FONT_COLOR: new Color('#ffffff'),
    },
    AutonomyState: {
      COLOR: new RangedVibrance('#FF0000', 0xff, 0x66, [100, 200, 300, 400, 500]),
      SHAPE: 'rect',
      FILL_STYLE: 'filled',
      FONT_COLOR: new Color('#ffffff'),
    },
    Cleared: {
      COLOR: new Color('#4a4a4a88'),
      SHAPE: 'rect',
      FILL_STYLE: 'filled',
      FONT_COLOR: new Color('#aaaaaa'),
    },
    Spurious: {
      COLOR: new Color('#7a5a5a'),
      SHAPE: 'rect',
      FILL_STYLE: 'filled',
      FONT_COLOR: new Color('#ffffff'),
    },
    Timeout: {
      COLOR: new RangedVibrance('#FF0000', 0xff, 0x66, [100, 200, 300, 400, 500]),
      SHAPE: 'rect',
      FILL_STYLE: 'filled',
      FONT_COLOR: new Color('#ffffff'),
    },
    Internal: {
      COLOR: new RangedVibrance('#FF0000', 0xff, 0x66, [100, 200, 300, 400, 500]),
      SHAPE: 'rect',
      FILL_STYLE: 'filled',
      FONT_COLOR: new Color('#ffffff'),
    },
  },
  EdgeType: {
    Default: {
      USE_TARGET_NODE: true,
      DEFAULT: 'green',
      WEIGHT: 1000000,
      STYLE: 'bold',
      ARROW_HEAD: 'open',
      FONT_COLOR: new Color('#cccccc'),
    },
  },
  ClusterType: {
    Topic: {
      COLOR: new Color('#3a3a3a'),
      FILL_STYLE: 'filled',
      FONT_COLOR: new Color('#cccccc'),
      LABEL: (err) => {
        return err.error.topic
      },
    },
    Internal: {
      COLOR: new Color('#3a3a3a'),
      FILL_STYLE: 'filled',
      FONT_COLOR: new Color('#acacfc'),
      LABEL: (err) => {
        return err.error.topic
      },
    },
    AutonomyState: {
      COLOR: new Gradient('#5a2a2a', '#1a1a1a'),
      FILL_STYLE: 'filled, radial, bold',
      FONT_COLOR: new Color('#ffffff'),
      LABEL: (_err) => {
        return 'Autonomy State (VI)'
      },
    },
  },
}

function generateGraph(data) {
  let graph = new SeriousVizness()
    .setRankDirection(Dot.Graph.RANK_DIRECTION)
    .setRankSeparation(Dot.Graph.RANK_SEPARATION)
    .setUseSplines(Dot.Graph.USE_SPLINES)
    .setSize(Dot.Graph.SIZE)
    .setBackground(Dot.Graph.BACKGROUND.shade())

  if (data === null) return graph

  // make the clusters and nodes
  let nodes = {}
  data.forEach((err) => {
    let uniqueCat = err.error.id + err.error.source + err.error.topic
    let fancyCat = err.error.id
    if (Dot.Node.SOURCE || Dot.Node.AGE) fancyCat += `\n`
    if (Dot.Node.SOURCE) fancyCat += `${Dot.Node.AGE_TAGLINE(err)}`
    if (Dot.Node.AGE) fancyCat += `${Dot.Node.SOURCE_TAGLINE(err)}`

    let nodeType = Dot.NodeType.Default
    if (Dot.Checks.IS_CLEARED(err)) nodeType = Dot.NodeType.Cleared
    else if (Dot.Checks.IS_SPURIOUS(err)) nodeType = Dot.NodeType.Spurious
    else if (Dot.Checks.IS_AUTONOMY_STATE(err)) nodeType = Dot.NodeType.AutonomyState
    else if (Dot.Checks.IS_ROOT(err)) nodeType = Dot.NodeType.Root

    let node = graph
      .node(uniqueCat)
      .setLabel(fancyCat)
      .setColor(nodeType.COLOR.shade(err.age))
      .setShape(nodeType.SHAPE)
      .setStyle(nodeType.FILL_STYLE)
      .setFontColor(nodeType.FONT_COLOR.shade())

    let clusterType = Dot.ClusterType.Topic
    if (Dot.Checks.IS_AUTONOMY_STATE(err)) clusterType = Dot.ClusterType.AutonomyState

    let cluster = graph
      .cluster(err.error.topic)
      .setLabel(clusterType.LABEL(err))
      .setColor(clusterType.COLOR.shade())
      .setStyle(clusterType.FILL_STYLE)
      .setFontColor(clusterType.FONT_COLOR.shade())

    nodes[err.ref] = node
    graph.attach(node, cluster)
  })

  // make the edges
  data.forEach((err) => {
    let effect = nodes[err.ref]
    err.immediate_causes?.forEach((causeRef) => {
      let cause = nodes[causeRef]
      let edgeType = Dot.EdgeType.Default

      graph
        .edge(cause, effect)
        .setColor(edgeType.USE_TARGET_NODE ? effect.color : edgeType.DEFAULT)
        .setStyle(edgeType.STYLE)
        .setWeight(edgeType.WEIGHT)
        .setArrowHead(edgeType.ARROW_HEAD)
        .setFontColor(edgeType.FONT_COLOR.shade())
    })
  })

  return graph
}

export { Dot, generateGraph }
