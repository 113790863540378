import { RootCauseError } from 'src/models'
import { ComputedRef, computed } from 'vue'
import { useConfigStore, useFaultsStore } from 'src/stores/'

export const sensorFaultStoreInternal = () => {
  const faultsStore = useFaultsStore()
  const configStore = useConfigStore()

  const activeSensorFaults: ComputedRef<RootCauseError[]> = computed(() => {
    const [lower, upper] = configStore.sensorComponentIdRange
    const faults: RootCauseError[] = []
    for (const rce of faultsStore.rootCauseErrors) {
      if (rce.component_id >= lower && rce.component_id <= upper) {
        faults.push(rce)
      }
    }
    return faults
  })

  return {
    activeSensorFaults,
  }
}
