import { datadogLogs, StatusType } from '@datadog/browser-logs'
import { AxiosResponse } from 'axios'
/**
 * Enum representing various log keys used in the application.
 * These keys are used to identify different types of logs or events.
 *
 * @enum {string}
 */
export enum LogKeys {
  /** Key for logging the latest health monitoring data */
  HEALTH_MON_LATEST = 'HEALTH_MON_LATEST',
  /** Key for logging the list of health monitoring data */
  HEALTH_MON_LIST = 'HEALTH_MON_LIST',
  /** Key for logging 5xx errors in health monitoring */
  HEALTH_MON_5xx_ERROR = 'HEALTH_MON_5xx_ERROR',
  /** Key for logging when no vehicle data is available */
  VEHICLE_NO_DATA = 'VEHICLE_NO_DATA',
  /** Key for logging 5xx errors related to vehicle data */
  VEHICLE_5xx_ERROR = 'VEHICLE_5xx_ERROR',
  /** Key for logging when vehicle is found */
  VEHICLE_FOUND = 'VEHICLE_FOUND',
  /** Key for logging when the fault buffer is used. */
  FAULT_BUFFER_USED = 'FAULT_BUFFER_USED',
  /** Key for logging when the fault buffer is not used and we need fetch data. */
  FAULT_BUFFER_NOT_USED = 'FAULT_BUFFER_NOT_USED',
}

// Define the Record type for messages
type LogRecord = Record<LogKeys, string>

/**
 * A constant object that maps LogKeys to their corresponding log messages.
 * This object provides a centralized place to store and manage log messages used throughout the application.
 *
 * @constant
 * @type {LogRecord}
 *
 * @property {string} HEALTH_MON_LATEST - Message for logging the elapsed time when fetching the latest fault.
 * @property {string} HEALTH_MON_LIST - Message for logging the elapsed time when fetching a list of faults.
 * @property {string} VEHICLE_NO_DATA - Message for logging when no vehicle data is available.
 * @property {string} VEHICLE_FOUND - Message for logging when a vehicle is found.
 * @property {string} VEHICLE_5xx_ERROR - Message for logging 5xx errors related to vehicle data.
 * @property {string} HEALTH_MON_5xx_ERROR - Message for logging 5xx errors in health monitoring.
 * @property {string} FAULT_BUFFER_USED - Message for logging when the fault buffer is used for navigation.
 * @property {string} FAULT_BUFFER_NOT_USED - Message for logging when external API is used for fault navigation.
 */
export const LogMessages: LogRecord = {
  [LogKeys.HEALTH_MON_LATEST]: 'elapsed time fetching latest fault:',
  [LogKeys.HEALTH_MON_LIST]: 'elapsed time fetching list of faults:',
  [LogKeys.VEHICLE_NO_DATA]: 'no vehicles available',
  [LogKeys.VEHICLE_FOUND]: 'vehicle found',
  [LogKeys.VEHICLE_5xx_ERROR]: 'server error 5xx',
  [LogKeys.HEALTH_MON_5xx_ERROR]: 'server error 5xx',
  [LogKeys.FAULT_BUFFER_USED]: 'fault buffer used when navigating fault events',
  [LogKeys.FAULT_BUFFER_NOT_USED]: 'external API used when navigating fault events',
}
/**
 * Enum representing different types of metrics that can be logged.
 * These metric types are used to categorize and classify logged data.
 *
 * @enum {string}
 */
export enum MetricType {
  /** Represents metrics related to system reliability */
  RELIABILITY = 'RELIABILITY',
  /** Represents metrics related to system performance */
  PERFORMANCE = 'PERFORMANCE',
}
/**
 * Logs the following reliability metric: Health Monitoring API's response time when fetching latest faults.
 * @param {Date} startTime - The timestamp when the request was started.
 * @param {Date} endTime - The timestamp when the response was received.
 */
export function LogElapsedTimeFetchLatestFault(
  startTime: Date,
  endTime: Date,
  response: AxiosResponse
) {
  const diff = endTime.getTime() - startTime.getTime()
  if (diff < 0) {
    return
  }

  const messageContext = {
    elapsed_milliseconds: diff,
    status: response.status,
    statusText:
      response.status >= 400
        ? response.statusText || (response.data as Record<string, unknown>)
        : response.statusText,
    url: response.config?.url ?? 'Unknown URL',
    log_key: LogKeys.HEALTH_MON_LATEST,
  }

  // Log different cases: Info (under 2 seconds), Warning (under 4 seconds), Error (more than 4 seconds)
  if (diff < 2000) {
    LogDataDogMessage(
      MetricType.RELIABILITY,
      'health-monitoring-api',
      `${LogMessages[LogKeys.HEALTH_MON_LATEST]} ${diff}ms`,
      'info',
      messageContext
    )
  } else {
    LogDataDogMessage(
      MetricType.RELIABILITY,
      'health-monitoring-api',
      `${LogMessages[LogKeys.HEALTH_MON_LATEST]} ${diff}ms`,
      'warn',
      messageContext
    )
  }
}
export function LogElapsedTimeFetchVehicle(
  startTime: Date,
  endTime: Date,
  response: AxiosResponse,
  logKey: LogKeys
) {
  const diff = endTime.getTime() - startTime.getTime()
  if (diff < 0) {
    return
  }

  const messageContext = {
    elapsed_milliseconds: diff,
    status: response.status,
    statusText:
      response.status >= 400
        ? response.statusText || (response.data as Record<string, unknown>)
        : response.statusText,
    url: response.config?.url ?? 'Unknown URL',
    log_key: logKey,
  }

  // Log different cases: Info (under 2 seconds), Warning (under 4 seconds), Error (more than 4 seconds)
  if (diff < 2000) {
    LogDataDogMessage(
      MetricType.RELIABILITY,
      'vehicles-api',
      `${LogMessages[logKey]} ${diff}ms`,
      'info',
      messageContext
    )
  } else {
    LogDataDogMessage(
      MetricType.RELIABILITY,
      'vehicles-api',
      `${LogMessages[logKey]} ${diff}ms`,
      'warn',
      messageContext
    )
  }
}
/**
 * Logs a message to DataDog with the specified metric type, feature, message, status, and context.
 *
 * @param metricType - The type of metric being logged (e.g., RELIABILITY, PERFORMANCE).
 * @param feature - The feature or component related to the log message.
 * @param log - The main content of the log message.
 * @param status - The status level of the log (info, warn, or error).
 * @param messageContext - Optional. Additional context to be included with the log message.
 *
 * @returns void This function doesn't return a value.
 */
export function LogDataDogMessage(
  metricType: MetricType,
  feature: string,
  log: string,
  status: StatusType,
  messageContext: object
) {
  const message = GetLogMessage(metricType, feature, log)
  const context = {
    ...messageContext,
    metric_type: metricType,
    feature: feature,
    log: log,
  }
  if (status === 'info') {
    datadogLogs.logger.info(message, { healthviz: context })
  } else if (status === 'warn') {
    datadogLogs.logger.warn(message, { healthviz: context })
  } else if (status === 'error') {
    datadogLogs.logger.error(message, { healthviz: context })
  }
}
/**
 * Logs the usage of the fault buffer to DataDog.
 * This function helps track whether the fault buffer was used or if external API was needed.
 *
 * @param {boolean} used - Indicates whether the fault buffer was used.
 *                         True if the buffer was used, false if external API was needed.
 * @returns {void} This function doesn't return a value.
 */
export function LogFaultBufferUsage(used: boolean) {
  const logKey = used ? LogKeys.FAULT_BUFFER_USED : LogKeys.FAULT_BUFFER_NOT_USED
  LogDataDogMessage(MetricType.PERFORMANCE, 'fault-buffer', LogMessages[logKey], 'info', {
    log_key: logKey,
  })
}
/**
 * Logs the following reliability metric: Health Monitoring API's response time when fetching list of faults by date range.
 * @param {Date} startTime - The timestamp when the request was started.
 * @param {Date} endTime - The timestamp when the response was received.
 */
export function LogElapsedTimeFetchFaultEventByDateWindow(
  startTime: Date,
  endTime: Date,
  response: AxiosResponse
) {
  const diff = endTime.getTime() - startTime.getTime()
  if (diff < 0) {
    return
  }

  const messageContext = {
    elapsed_milliseconds: diff,
    status: response.status,
    statusText:
      response.status >= 400
        ? response.statusText || (response.data as Record<string, unknown>)
        : response.statusText,
    url: response.config?.url ?? 'Unknown URL',
    log_key: LogKeys.HEALTH_MON_LIST,
  }

  // Log different cases: Info (under 2 seconds), Warning (under 4 seconds), Error (more than 4 seconds)
  if (diff < 2000) {
    LogDataDogMessage(
      MetricType.RELIABILITY,
      'health-monitoring-api',
      `${LogMessages[LogKeys.HEALTH_MON_LIST]} ${diff}ms`,
      'info',
      messageContext
    )
  } else {
    LogDataDogMessage(
      MetricType.RELIABILITY,
      'health-monitoring-api',
      `${LogMessages[LogKeys.HEALTH_MON_LIST]} ${diff}ms`,
      'warn',
      messageContext
    )
  }
}
/**
 * Formats a log message with metric type and feature information.
 *
 * @param metricType - The type of metric being logged (e.g., RELIABILITY, PERFORMANCE).
 * @param feature - The feature or component related to the log message.
 * @param message - The main content of the log message.
 * @returns A formatted string containing the metric type, feature, and message.
 */
export function GetLogMessage(metricType: MetricType, feature: string, message: string): string {
  return `[${metricType}] [${feature}] ${message}`
}
