export default class Color {
  constructor(color) {
    const self = this
    self.color = color
  }

  shade() {
    const self = this
    return self.color
  }
}
