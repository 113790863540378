<template>
  <q-tooltip
    :model-value="showTooltip && !navigationStore.isDocsPageOpen"
    no-parent-event
    class="bg-green-9 text-body1"
    :offset="[10, 10]"
    style="z-index: 10"
  >
    <slot></slot>
  </q-tooltip>
</template>

<script setup lang="ts">
import { onUnmounted, ref } from 'vue'
import { useNavigationStore } from 'src/stores'

const props = defineProps<{
  showDelayMs: number
}>()

const navigationStore = useNavigationStore()

const showTooltip = ref(false)
const hintTimeoutId = ref<NodeJS.Timeout>()

function show() {
  if (hintTimeoutId.value === undefined) {
    hintTimeoutId.value = setTimeout(() => {
      showTooltip.value = true
    }, props.showDelayMs)
  }
}

function hide() {
  clearTimeout(hintTimeoutId.value)
  hintTimeoutId.value = undefined
  showTooltip.value = false
}

defineExpose({ show, hide })

onUnmounted(() => {
  clearTimeout(hintTimeoutId.value)
})
</script>
