/*
Serious Vizness Edge
Robbie Grier
*/

export default class SVEdge {
  constructor(from, to) {
    const self = this
    self.from = from
    self.to = to
    self.label = ''
    self.color = 'white'
    self.style = 'solid'
    self.weight = '100000'
    self.arrowHead = 'empty'
    self.fontColor = 'white'
    self.fontStyle = 'helvetica'
  }

  setLabel(value) {
    const self = this
    self.label = value
    return self
  }

  setColor(value) {
    const self = this
    self.color = value
    return self
  }

  setWeight(value) {
    const self = this
    self.weight = value
    return self
  }

  setStyle(value) {
    const self = this
    self.style = value
    return self
  }

  setArrowHead(value) {
    const self = this
    self.arrowHead = value
    return self
  }

  setFontColor(value) {
    const self = this
    self.fontColor = value
    return self
  }

  setFontStyle(value) {
    const self = this
    self.fontStyle = value
    return self
  }
}
