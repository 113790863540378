import { ColClasses } from '.'
import { HealthItem, DataItem, GetTimestampAge } from './fault_event'

export interface RootCauseFrame {
  timestamp: string
  messages: RootCauseError[]
}

export interface RootCauseError {
  ref: number
  age: number
  error: {
    id: string
    source: string
    topic: string
  }
  immediate_causes: number[] | null
  is_immediate_cause: boolean
  verified: boolean
  cleared: boolean
  root_causes: number[]
  age_cleared: number
  ecu_id: number
  component_id: number
  context_id: number
  error_code: number
  component_name: string
  parameter_name: string
  item_name: string
  item_description: string
  item_type: number
  fault_type: number
  data_list: DataItem[]
}

export function healthItemsToRootCauseErrors(
  hi: HealthItem[] | undefined,
  timestamp: number
): RootCauseError[] {
  if (!hi) {
    return []
  }
  return Array.from(hi, (item) => {
    return {
      ref: item.ref,
      age: GetTimestampAge(timestamp, item.activation_timestamp),
      error: {
        id: `${item.item_name}`,
        source: `${item.parameter_name}`,
        topic: `${item.component_name}`,
      },
      immediate_causes: item.immediate_blame_refs,
      is_immediate_cause: item.is_immediate_blame,
      verified: item.verified,
      cleared: item.cleared,
      root_causes: item.root_causes,
      age_cleared: GetTimestampAge(timestamp, item.cleared_timestamp),
      ecu_id: item.ecu_id,
      component_id: item.component_id,
      context_id: item.context_id,
      error_code: item.error_code,
      component_name: item.component_name,
      parameter_name: item.parameter_name,
      item_name: item.item_name,
      item_description: item.item_description,
      item_type: item.item_type,
      fault_type: item.fault_type,
      data_list: item.data_list,
    }
  })
}

export interface ErrorMap {
  [key: RootCauseError['ref']]: RootCauseError
}

export const rootCauseColClasses: ColClasses = {
  'size-xl': {
    title: 'col-4',
    btns: 'col-4',
  },
  'size-lg': {
    title: 'col-4',
    btns: 'col-4',
  },
  'size-md': {
    title: 'col-12',
    btns: 'col-6 offset-3', // center
  },
  'size-sm': {
    title: 'col-12',
    btns: 'col-6 offset-3', // center
  },
  'size-xs': {
    title: 'col-12',
    btns: 'col-12',
  },
}
