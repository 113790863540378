import { ref } from 'vue'

export const configStoreInternal = () => {
  const maxFaultEventBufferSize = ref(1000)
  const countFaultsPerRequestsHealthMonitoringApi = ref(20)
  const liveModeFetchIntervalMilliseconds = ref(2000)
  const timeGapNotifyMilliseconds = ref(2500)
  // must be either 'LR' (left-right) or 'TB' (top-bottom)
  const traceRenderDirection = ref<'LR' | 'TB'>('LR')
  const sensorComponentIdRange = ref([5000, 5999])
  const vehicleIdUrlParam = ref('veh_id')
  const faultTimestampUrlParam = ref('ts')
  // A mapping of { component_id: priority } for use in sorting faults by component priority
  const componentFaultPriority = ref<{ [key: number]: number }>({
    // Group 1 - CAN Input
    3001: 1,
    // Group 2
    3002: 2,
    4: 2,
    // Group 3 - Sensors
    5000: 3,
    5001: 3,
    5002: 3,
    5003: 3,
    5004: 3,
    5005: 3,
    5006: 3,
    5007: 3,
    // Group 4 - Perception
    1000: 4,
    1001: 4,
    1002: 4,
    1003: 4,
    1004: 4,
    1005: 4,
    1006: 4,
    1007: 4,
    1008: 4,
    1009: 4,
    // Group 5 - Vehicle Intent
    2000: 5,
    2001: 5,
    2002: 5,
    2003: 5,
    2004: 5,
    2005: 5,
    2006: 5,
    2007: 5,
    // Group 6 - Misc. Vehicle Control
    1: 6,
    2: 6,
    3: 6,
    // Group 7 - Mission Control
    4001: 7,
    4002: 7,
  })

  return {
    maxFaultEventBufferSize,
    countFaultsPerRequestsHealthMonitoringApi,
    liveModeFetchIntervalMilliseconds,
    timeGapNotifyMilliseconds,
    traceRenderDirection,
    sensorComponentIdRange,
    vehicleIdUrlParam,
    faultTimestampUrlParam,
    componentFaultPriority,
  }
}
