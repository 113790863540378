<template>
  <div class="row q-pa-md bg-grey-9_5 justify-between">
    <div class="col-md-3 col-12" :class="useQuasar().screen.lt.md ? 'q-mb-md' : ''">
      <VehicleSelection
        @vehicle-input-focused="vehicleInputFocused = true"
        @vehicle-input-blurred="vehicleInputFocused = false"
      />
    </div>
    <div class="col-md-9 col-12">
      <TimeNavigation :vehicle-input-focused="vehicleInputFocused" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useQuasar } from 'quasar'

import TimeNavigation from 'src/components/TimeNavigation.vue'
import VehicleSelection from 'src/components/VehicleSelection.vue'

import { ref } from 'vue'

const vehicleInputFocused = ref(false)
</script>
