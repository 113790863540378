export interface User {
  email: string
  attributes: string[]
  created: string
  display_roles: string[]
  last_login_ts: string
  last_login_version: string
  last_updated: string
  preferences: Record<string, unknown>
  roles: string[]
}

export const RolesWithPermission: string[] = ['Break Room', 'Test Engineer', 'Super Admin']
