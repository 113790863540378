<template>
  <Navbar
    @faults-selected="onTabSelected(NavigationPage.ROOT_CAUSES)"
    @trace-selected="onTabSelected(NavigationPage.TRACE)"
    @sensors-selected="onTabSelected(NavigationPage.SENSORS)"
    @docs-selected="onTabSelected(NavigationPage.DOCUMENTATION)"
  />

  <ActionBar v-show="currentPage !== NavigationPage.DOCUMENTATION" />

  <!-- Page with content -->
  <RootCauses v-if="currentPage === NavigationPage.ROOT_CAUSES" />
  <Trace v-if="currentPage === NavigationPage.TRACE" />
  <Sensors v-if="currentPage === NavigationPage.SENSORS" />
  <Documentation v-if="currentPage === NavigationPage.DOCUMENTATION" />

  <PermissionChecker />
</template>

<script lang="ts" setup>
import { ref, onBeforeMount, onMounted, onUnmounted } from 'vue'
import { useQuasar, Notify } from 'quasar'

import Navbar from 'src/layouts/Navbar.vue'
import ActionBar from 'src/layouts/ActionBar.vue'
import RootCauses from 'src/pages/RootCauses.vue'
import Trace from 'src/pages/Trace.vue'
import Sensors from 'src/pages/Sensors.vue'
import Documentation from 'src/pages/Documentation.vue'
import PermissionChecker from 'src/components/PermissionChecker.vue'
import { sanitize } from './stores/util'

import {
  useTimeUpdateStore,
  useNavigationStore,
  useUsersStore,
  useVehiclesStore,
  useConfigStore,
} from 'src/stores'
import { NavigationPage } from 'src/models'

const currentPage = ref(NavigationPage.ROOT_CAUSES)

const vehiclesStore = useVehiclesStore()
const timeUpdateStore = useTimeUpdateStore()
const configStore = useConfigStore()

const quasar = useQuasar()
const initialTheme = quasar.dark.mode

const navigationStore = useNavigationStore()

function onTabSelected(newCurrentPage: NavigationPage) {
  navigationStore.isDocsPageOpen = newCurrentPage === NavigationPage.DOCUMENTATION
  currentPage.value = newCurrentPage
}

async function loadSelections(location: Location) {
  // These can't be done in their respective components because in order to activate a timestamp,
  // there must also be a vehicle selected
  vehiclesStore.setVehiclesLoading(true)
  await vehiclesStore.updateVehiclesList()
  vehiclesStore.setVehiclesLoading(false)

  const currentURL = new URL(location.href)
  const vehicleIdToSelect = sanitize(currentURL.searchParams.get(configStore.vehicleIdUrlParam))
  if (vehicleIdToSelect) {
    const foundVehicle = vehiclesStore.vehiclesList.find(
      (vehicle) => vehicle.vehicle_id === vehicleIdToSelect
    )
    if (foundVehicle) {
      vehiclesStore.setSelectedVehicleId(foundVehicle.vehicle_id)

      // Since a vehicle with the ID passed in via url was found, activate the timestamp that
      // was given (if there is one)
      const sanitizedTs = sanitize(currentURL.searchParams.get(configStore.faultTimestampUrlParam))
      const parsedTs = parseInt(sanitizedTs, 10)
      if (!isNaN(parsedTs)) {
        try {
          const urlTsIsoString = new Date(parsedTs).toISOString()
          if (urlTsIsoString) {
            await timeUpdateStore.activateFaultEventTimestamp(parsedTs)
          }
        } catch (RangeError) {
          console.warn(`Invalid timestamp provided in URL query params (${sanitizedTs})`)
        }
      }
    } else {
      console.warn(`Invalid vehicle id provided in URL query params (${vehicleIdToSelect})`)
      Notify.create({
        type: 'negative',
        icon: 'warning',
        message: `Could not find any vehicle ids that match the URL parameter (${vehicleIdToSelect})`,
      })
    }
  }
}

onBeforeMount(() => {
  quasar.dark.set(true)
})

onMounted(async () => {
  await useUsersStore().initUser()

  await loadSelections(window.location)
})

onUnmounted(() => {
  quasar.dark.set(initialTheme)

  const timeUpdateStore = useTimeUpdateStore()
  timeUpdateStore.deactivateLiveModeTicker()
})
</script>
