/**
 * Enum for navigation mode
 */
export enum Mode {
  NONE = 'None',
  PLAYBACK = 'Playback',
  LIVE = 'Live',
}

/**
 * Enum for navigation pages/tabs
 */
export enum NavigationPage {
  ROOT_CAUSES = 'RootCauses',
  TRACE = 'Trace',
  SENSORS = 'Sensors',
  DOCUMENTATION = 'Documentation',
}
