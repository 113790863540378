import axios, { AxiosResponse, AxiosError } from 'axios'
import { ErrorMessage } from 'src/models'
import { Notify } from 'quasar'

// Guard function that asserts the given value is an object with the a property of the given name
export function hasOwnProperty<K extends PropertyKey>(
  obj: unknown,
  name: K
): obj is Record<K, unknown> {
  return (typeof obj === 'object' && obj?.hasOwnProperty(name)) || false
}

// Get the specified header *safely* from the HTTP response
export function getResponseHeader<T>(res: AxiosResponse<T>, name: string): string | undefined {
  // Axios always lower-cases header names (#413)
  name = name.toLowerCase()
  if (hasOwnProperty(res.headers, name)) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const header = res.headers[name]
    if (header && typeof header === 'string') {
      return header
    }
  }
}

export function getAuthTokenFromLocalStorage(): string {
  const suffix = 'idToken'
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i)
    if (key !== null && key.endsWith(suffix)) {
      return localStorage.getItem(key) || ''
    }
  }
  // If no localStorage auth token exists (such as when running locally), this envToken is used instead
  return String(import.meta.env.VITE_ID_TOKEN_TEST)
}

// Get the *best* message from the error or use the default if provided
export function getErrorMessage(res: AxiosResponse<unknown>): ErrorMessage {
  const errmsg = {
    message: res.statusText || 'Unknown status',
    code: res.status || 0,
  }

  if (hasOwnProperty(res.data, 'code')) {
    errmsg.code = Number(res.data.code) || errmsg.code
  }

  if (hasOwnProperty(res.data, 'message')) {
    const message = res.data.message
    if (message && typeof message === 'string') {
      errmsg.message = message
    }
  }

  if (hasOwnProperty(res.data, 'details') && Array.isArray(res.data.details)) {
    const details: unknown[] = res.data.details
    for (const detail of details) {
      if (hasOwnProperty(detail, 'message')) {
        const message = detail.message
        if (message && typeof message === 'string') {
          errmsg.message += ' - ' + message
        }
      }
    }
  }

  if (hasOwnProperty(res.data, 'detail')) {
    errmsg.message = JSON.stringify(res.data.detail)
  }

  if (errmsg.message === 'Unknown status') {
    errmsg.message = JSON.stringify(res.data)
  }

  return errmsg
}

export function insertWordBreaks(inputString: string): string {
  // Add a unicode zero-width space after / or _ to enable word wrapping for those chars
  return inputString.replace(/([/_])/g, '$1\u200B')
}

export class APIError extends Error implements ErrorMessage {
  public title: string

  public code: number

  constructor(message?: string, code = 0, title = 'API Error') {
    super(message?.replace(/^"|"$/g, '')) //Sanitizing double-quotes in the message
    this.code = code
    this.title = title
  }

  public toString(): string {
    return `${this.code >= 200 ? '[' + String(this.code) + '] ' : ''} ${this.title}: ${
      this.message
    }`
  }
  static fromError(error: unknown, title?: string): APIError {
    if (!error) {
      return new APIError('Undefined error', undefined, title)
    }

    if (axios.isAxiosError(error) && error.response) {
      const errmsg: ErrorMessage = getErrorMessage(error.response)
      return new APIError(errmsg.message, errmsg.code, title)
    }

    if (hasOwnProperty(error, 'message')) {
      const message = error.message
      if (message && typeof message === 'string') {
        return new APIError(message, undefined, title)
      }
    }

    if (error instanceof Error) {
      const message = error.message
      if (message && typeof message === 'string') {
        return new APIError(message, undefined, title)
      }
    }
    return new APIError('Unknown error', undefined, title)
  }
}

const units: { unit: Intl.RelativeTimeFormatUnit; ms: number }[] = [
  { unit: 'year', ms: 31536000000 },
  { unit: 'month', ms: 2628000000 },
  { unit: 'day', ms: 86400000 },
  { unit: 'hour', ms: 3600000 },
  { unit: 'minute', ms: 60000 },
  { unit: 'second', ms: 1000 },
]
const rtf = new Intl.RelativeTimeFormat('en', { numeric: 'auto' })

/**
 * Get language-sensitive relative time message from Dates.
 * @param relative  - the relative dateTime, generally is in the past or future
 * @param pivot     - the dateTime of reference, generally is the current time
 */
export function relativeTimeFromDates(relative: Date | null, pivot: Date = new Date()): string {
  if (!relative) {
    return ''
  }
  const elapsed = relative.getTime() - pivot.getTime()
  return relativeTimeFromElapsed(elapsed)
}

/**
 * Get language-sensitive relative time message from elapsed time.
 * @param elapsed   - the elapsed time in milliseconds
 */
export function relativeTimeFromElapsed(elapsed: number): string {
  for (const { unit, ms } of units) {
    if (Math.abs(elapsed) >= ms || unit === 'second') {
      return rtf.format(Math.round(elapsed / ms), unit)
    }
  }
  return ''
}

/**
 * Get english time difference between timestamp (1 hour before, 2 days after, etc), with millisecond precision
 * @param target      - the target timestamp
 * @param reference   - the reference timestamp
 * @param showSuffix  - toggle displaying the before/later suffix
 */
export function timeDifference(target: number, reference: number, showSuffix: boolean): string {
  const diffMs = target - reference
  const suffix = diffMs < 0 ? 'before' : 'later'

  const diffMsAbsolute = Math.abs(diffMs)
  if (diffMsAbsolute === 0) {
    return 'exact match'
  }

  if (diffMsAbsolute < 1000) {
    return `
      ${diffMsAbsolute} millisecond${diffMsAbsolute === 1 ? '' : 's'} ${showSuffix ? suffix : ''}
    `.trim()
  }

  let diff = 0
  let unitPluralized = ''

  for (const u of units) {
    if (diffMsAbsolute < u.ms) {
      continue
    }
    diff = Math.floor(diffMsAbsolute / u.ms)

    unitPluralized = diff === 1 ? u.unit : `${u.unit}s`
    break
  }

  return `${diff} ${unitPluralized} ${showSuffix ? suffix : ''}`.trim()
}

/**
 * Logs an axios error on console and displays toaster notification
 * @param {AxiosError} error - The axios error to log and notify about
 * @param {AxiosError} title - Error title
 */
export function LogAndNotifyAxiosError(error: AxiosError, title = '') {
  const apiError = APIError.fromError(error, title)

  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    if (error.response.status >= 400 && error.response.status < 500) {
      console.warn(apiError)
      Notify.create({
        type: 'warning',
        message: apiError.message,
      })
    } else if (error.response.status >= 500) {
      console.error(apiError)
      Notify.create({
        type: 'negative',
        message: apiError.message,
      })
    }
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.error('Axios request error', error.request)
    console.log(error.toJSON())
    Notify.create({
      type: 'negative',
      message: apiError.message,
    })
  } else {
    // Something happened in setting up the request that triggered an Error
    console.error('Error', apiError.toString())
  }
}

/**
 * Sanitizes URL params to help prevent XSS
 * @param {string} input - The URL param to sanitize
 */
export function sanitize(input: string | null) {
  if (input === null) return ''
  const map: Record<string, string> = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#x27;',
    '/': '&#x2F;',
  }
  const reg = /[&<>"'/]/gi

  return input.replace(reg, (match) => map[match])
}
