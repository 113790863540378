/*
Serious Vizness Cluster
Robbie Grier
*/

export default class SVCluster {
  constructor(name, label) {
    const self = this
    self.name = name
    self.label = label
    self.color = 'lightblue'
    self.style = 'filled'
    self.fontColor = 'white'
    self.fontStyle = 'helvetica'
    self.nodes = []
  }

  setLabel(value) {
    const self = this
    self.label = value
    return self
  }

  setColor(value) {
    const self = this
    self.color = value
    return self
  }

  setStyle(value) {
    const self = this
    self.style = value
    return self
  }

  setFontColor(value) {
    const self = this
    self.fontColor = value
    return self
  }

  setFontStyle(value) {
    const self = this
    self.fontStyle = value
    return self
  }
}
