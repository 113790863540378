<template>
  <q-dialog persistent :model-value="!usersStore.userHasRequiredPermission">
    <q-card v-if="usersStore.userHasRequiredPermission === undefined" class="spinner-card">
      <q-card-section class="spinner-card-section">
        <div>
          <q-spinner color="primary" size="6em" :thickness="2" />
        </div>
        <h5>&emsp;Loading user...</h5>
      </q-card-section>
    </q-card>

    <q-card v-else style="width: 700px; max-width: 80vw">
      <q-card-section>
        <div class="text-h5">Missing role</div>
      </q-card-section>

      <q-card-section class="text-body1">
        <p>
          You need the following role in order to use this tool:
          <q-chip color="grey-9"> {{ RolesWithPermission[0] }} </q-chip>
        </p>
        <p>
          It seems like your user does not have that role assigned yet. Please request to be
          assigned this role on the following Slack channel:
          <q-btn
            no-caps
            unelevated
            dense
            icon="img:https://cdn.worldvectorlogo.com/logos/slack-new-logo.svg"
            color="purple-9"
            href="https://torcrobotics.enterprise.slack.com/archives/C05E228G4RW"
            target="_blank"
            label="#team_mission_management"
          />
        </p>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script lang="ts" setup>
import { useUsersStore } from 'src/stores'
import { RolesWithPermission } from 'src/models'

const usersStore = useUsersStore()
</script>

<style lang="scss" scoped>
.spinner-card {
  width: 700px;
  max-width: 80vw;
  height: 300px;
  max-height: 50vh;
  display: flex;
  justify-content: center;
}

.spinner-card-section {
  display: flex;
  align-items: center;
}
</style>
