export default class RangedVibrance {
  constructor(shade, start, end, values) {
    const self = this
    self.values = values
    self.cols = []
    let hueStep = (start - end) / values.length
    let huePos = start
    for (let i = values.length; i >= 0; i--) {
      self.cols.push(shade + huePos.toString(16))
      huePos -= hueStep
    }
    self.index = 0
  }

  shade(value) {
    const self = this
    for (let i = self.values.length; i >= 0; i--) {
      if (value > self.values[i]) {
        return self.cols[i]
      }
    }
    return self.cols[0]
  }
}
