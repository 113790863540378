<template>
  <q-toolbar class="bg-wet-slate">
    <q-btn class="q-px-sm" flat stretch no-caps size="sm" @click="onLogoSelected">
      <img :src="`${baseUrl}images/torc_logo_white.svg`" style="height: 24px" />
      <q-toolbar-title>
        HealthViz
        <q-badge color="grey-4" outline align="top">Offboard</q-badge>
      </q-toolbar-title>
    </q-btn>

    <q-btn
      flat
      stretch
      icon="report"
      :class="{ 'bg-grey-9_5': selection === 'faults' }"
      label="Faults"
      aria-label="Faults"
      no-caps
      @click="onFaultsSelected"
    />

    <q-btn
      flat
      stretch
      icon="account_tree"
      :class="{ 'bg-grey-9_5': selection === 'trace' }"
      label="Trace"
      aria-label="Trace"
      no-caps
      @click="onTraceSelected"
    />

    <q-btn
      flat
      stretch
      icon="sensors"
      :class="{ 'bg-grey-9_5': selection === 'sensors' }"
      label="Sensors"
      aria-label="Sensors"
      no-caps
      @click="onSensorsSelected"
    >
      <q-badge v-if="sensorsStore.activeSensorFaults.length" class="navbar-float">
        {{ sensorsStore.activeSensorFaults.length }}
      </q-badge>
    </q-btn>

    <q-btn
      flat
      stretch
      icon="info"
      :class="{ 'bg-grey-9_5': selection === 'docs' }"
      label="Docs"
      aria-label="Docs"
      no-caps
      @click="onDocsSelected"
    />
  </q-toolbar>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import { DatadogRumElement, DatadogRumInput, DatadogRumMethod } from 'src/models'
import { useSensorFaultStore } from 'src/stores'
import { datadogRum } from '@datadog/browser-rum'

const baseUrl = import.meta.env.BASE_URL

const emit = defineEmits(['faultsSelected', 'traceSelected', 'sensorsSelected', 'docsSelected'])

const sensorsStore = useSensorFaultStore()

const selection = ref('faults')

function onLogoSelected() {
  selection.value = 'faults'
  emit('faultsSelected')

  datadogRum.addAction('Navbar Logo btn', {
    element: DatadogRumElement.NavbarLogoBtn,
    method: DatadogRumMethod.ButtonClick,
    input: DatadogRumInput.MouseTouchpad,
  })
}

function onFaultsSelected() {
  selection.value = 'faults'
  emit('faultsSelected')

  datadogRum.addAction('Navbar Faults btn', {
    element: DatadogRumElement.NavbarFaultsBtn,
    method: DatadogRumMethod.ButtonClick,
    input: DatadogRumInput.MouseTouchpad,
  })
}

function onTraceSelected() {
  selection.value = 'trace'
  emit('traceSelected')

  datadogRum.addAction('Navbar Trace btn', {
    element: DatadogRumElement.NavbarTraceBtn,
    method: DatadogRumMethod.ButtonClick,
    input: DatadogRumInput.MouseTouchpad,
  })
}

function onSensorsSelected() {
  selection.value = 'sensors'
  emit('sensorsSelected')

  datadogRum.addAction('Navbar Sensors btn', {
    element: DatadogRumElement.NavbarSensorsBtn,
    method: DatadogRumMethod.ButtonClick,
    input: DatadogRumInput.MouseTouchpad,
  })
}

function onDocsSelected() {
  selection.value = 'docs'
  emit('docsSelected')

  datadogRum.addAction('Navbar Docs btn', {
    element: DatadogRumElement.NavbarDocsBtn,
    method: DatadogRumMethod.ButtonClick,
    input: DatadogRumInput.MouseTouchpad,
  })
}
</script>

<style lang="scss" scoped>
// Based heavily off the q-badge `floating` class but modified to better suit the navbar
.navbar-float {
  position: absolute;
  top: 2px;
  right: 0;
}
</style>
