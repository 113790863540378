export enum SystemFaultErrorCodes {
  MRMRequest = 60001,
  SystemUnhealthy = 60002,
}
export const SystemFaultErrorCodesValues = Object.values(SystemFaultErrorCodes)

export interface FaultEvent {
  event_uuid: string
  mission_uuid: string
  vehicle_id: string
  timestamp: number
  fault_message: FaultMessage
  creation_ts: number
  expiration_ts: number
}

export interface DataItem {
  key: string
  value: string
}

export interface HealthItem {
  ref: number
  verified: boolean
  cleared: boolean
  activation_timestamp: number
  cleared_timestamp: number
  ecu_id: number
  component_id: number
  context_id: number
  error_code: number
  component_name: string
  parameter_name: string
  item_name: string
  item_description: string
  item_type: number
  fault_type: number
  immediate_blame_refs: number[]
  data_list: DataItem[]
  root_causes: number[]
  is_immediate_blame: boolean
}

interface FaultMessage {
  timestamp: number
  health_items: HealthItem[]
}

export function GetTimestampAge(current: number, timestamp: number): number {
  if (timestamp <= 0) {
    return 0
  }
  return Math.max(current - timestamp, 0)
}
