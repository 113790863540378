import { createApp } from 'vue'
import { Quasar, Loading, Meta, Notify } from 'quasar'
import axios from 'axios'
import { getAuthTokenFromLocalStorage } from 'src/stores/util'

// Import icon and font libraries
import '@quasar/extras/roboto-font/roboto-font.css'
import '@quasar/extras/material-icons/material-icons.css'

// Import Quasar and custom CSS
import 'quasar/src/css/index.sass'

import { createPinia } from 'pinia'

// Datadog
import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'

export default function initOffboardHealthViz(vueInstance: ReturnType<typeof createApp>) {
  // Quasar
  vueInstance.use(Quasar, {
    plugins: { Loading, Meta, Notify }, // import Quasar plugins and add here
  })

  // Pinia
  const pinia = createPinia()
  vueInstance.use(pinia)

  // Datadog setup
  bootDatadog()
  bootAxios()
}

function bootAxios() {
  // Add auth token with a request interceptor
  axios.interceptors.request.use((config) => {
    const token = getAuthTokenFromLocalStorage()
    config.headers.Authorization = `Bearer ${token}`
    return config
  })
}

function bootDatadog() {
  if (import.meta.env.DEV) {
    // Disable datadog if in development mode
    return
  }

  // Datadog browser logs configuration
  datadogLogs.init({
    clientToken: String(import.meta.env.VITE_DATADOG_LOGS_CLIENT_TOKEN),
    site: 'datadoghq.com',
    service: 'offboard-healthviz',
    env: import.meta.env.MODE,
    version: String(import.meta.env.VITE_GIT_SHORT_SHA),
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    trackSessionAcrossSubdomains: false,
    useSecureSessionCookie: false,
  })
  datadogLogs.setGlobalContext({ team: 'vehicle-lifecycle' })

  // Datadog Real User Monitoring configuration
  datadogRum.init({
    applicationId: String(import.meta.env.VITE_DATADOG_RUM_APPLICATION_ID),
    clientToken: String(import.meta.env.VITE_DATADOG_LOGS_CLIENT_TOKEN),
    site: 'datadoghq.com',
    service: 'offboard-healthviz',
    env: import.meta.env.MODE,
    version: String(import.meta.env.VITE_GIT_SHORT_SHA),
    sessionSampleRate: 100,
    trackUserInteractions: false,
    defaultPrivacyLevel: 'mask-user-input',
    trackSessionAcrossSubdomains: false,
    useSecureSessionCookie: false,
    useCrossSiteSessionCookie: false,
  })
  datadogRum.setGlobalContext({ team: 'vehicle-lifecycle' })
}
