<template>
  <q-card
    :class="
      rootCauseError.cleared
        ? 'bg-dark border-positive'
        : isMRM
          ? 'bg-dark border-negative'
          : 'bg-grey-10'
    "
    style="max-width: calc(50% - 8px)"
  >
    <q-card-section class="root-cause-header q-pb-xs row" style="justify-content: space-between">
      <!-- Add styling and for cleared cards: darker bg, border, green text, check mark -->
      <div
        :class="[
          'text-h6',
          {
            'text-positive': rootCauseError.cleared,
            'text-orange': isMRM && !rootCauseError.cleared,
          },
        ]"
        data-test="card-error-id"
      >
        {{ rootCauseError.error.id }}
      </div>
      <q-icon
        v-if="rootCauseError.cleared"
        name="check"
        class="q-pl-xs text-weight-bold filter-green"
        style="align-self: center"
        size="sm"
      >
        <q-tooltip>Message Cleared</q-tooltip>
      </q-icon>
    </q-card-section>

    <div class="separator">Root Causes</div>

    <q-card-section class="q-pt-xs">
      <q-item
        v-for="rootCause in rootCauseError.root_causes"
        :key="rootCause"
        :root-cause-ref="rootCause"
        class="q-px-none"
        data-test="root-cause"
      >
        <q-item-section v-if="mappedErrors[rootCause] && mappedErrors[rootCause].error">
          <q-item-label class="root-cause-id text-weight-bold" data-test="root-cause-id">
            {{ mappedErrors[rootCause].error.id }}
            <span :class="colorByAge(mappedErrors[rootCause].age)">
              [{{ (mappedErrors[rootCause].age / 1000).toFixed(1) }}]
            </span>
          </q-item-label>
          <q-item-label
            v-if="mappedErrors[rootCause].error.topic"
            class="row"
            data-test="root-cause-topic"
          >
            <!-- Topic renamed to Parameter in ROS 2 -->
            <span class="label-width text-grey-5">Param</span>
            <span>{{ mappedErrors[rootCause].error.topic }}</span>
          </q-item-label>
          <q-item-label
            v-if="mappedErrors[rootCause].error.source"
            class="row"
            data-test="root-cause-source"
          >
            <span class="label-width text-grey-5">Source</span>
            <span>{{ mappedErrors[rootCause].error.source }}</span>
          </q-item-label>
        </q-item-section>
      </q-item>
    </q-card-section>
  </q-card>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { RootCauseError } from 'src/models'

const props = defineProps<{
  rootCauseError: RootCauseError
  mappedErrors: {
    [key: number]: RootCauseError
  }
  isMRM: boolean
}>()

/* Ages of all root causes */
const allAges = computed(() => {
  let ages: Set<number> = new Set()
  for (const key in props.mappedErrors) {
    ages.add(props.mappedErrors[key].age)
  }
  let agesArray: number[] = Array.from(ages)
  agesArray.sort((age1, age2) => age1 - age2)
  return agesArray
})

function colorByAge(mappedErrorAge: number) {
  const ages = allAges.value
  // A third in this context is not the mathematical top third. It's based on
  // the size of data set instead to eliminate the effect of outlier values
  const aThird = Math.round(ages.length * 0.33)
  const indexFound = ages.indexOf(mappedErrorAge) + 1
  if (indexFound <= aThird) {
    return 'text-green' // Newest third
  }
  if (indexFound <= aThird * 2) {
    return 'text-yellow' // Middle third
  }
  return 'text-orange' // Oldest third
}
</script>

<style lang="scss" scoped>
// Left green border for cleared messages
.border-positive {
  border-style: none none none solid;
  border-width: calc(map-get($space-sm, y) / 2);
  border-color: $positive;
}

// Left red border for messages
.border-negative {
  border-style: none none none solid;
  border-width: calc(map-get($space-sm, y) / 2);
  border-color: $negative;
}

.separator {
  display: flex;
  align-items: center;
  margin: 0 map-get($space-md, x);
  color: $grey-5;

  &::before,
  &::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid;
  }

  &::before {
    margin-right: map-get($space-sm, x);
  }
  &::after {
    margin-left: map-get($space-sm, x);
  }
}

.label-width {
  // Width of "Source" text with a couple extra pixels for spacing
  width: 52px;
}
</style>
