/*
Serious Vizness Node
Robbie Grier
*/

export default class SVNode {
  constructor(name) {
    const self = this
    self.name = name
    self.label = ''
    self.shape = 'rect'
    self.color = 'gray'
    self.fontColor = 'white'
    self.style = 'filled'
    self.fontStyle = 'helvetica'
  }

  setLabel(value) {
    const self = this
    self.label = value
    return self
  }

  setShape(value) {
    const self = this
    self.shape = value
    return self
  }

  setColor(value) {
    const self = this
    self.color = value
    return self
  }

  setFontColor(value) {
    const self = this
    self.fontColor = value
    return self
  }

  setStyle(value) {
    const self = this
    self.style = value
    return self
  }

  setFontStyle(value) {
    const self = this
    self.fontStyle = value
    return self
  }
}
